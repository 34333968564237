import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Grid from "@mui/joy/Grid";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AdminQuartzClientContext,
  CohortQuartzClientContext,
  StudyQuartzClientContext,
} from "../clients/contexts";
import SnackbarNotification from "../common/SnackbarNotification";
import HexagonBackground from "../components/HexagonBackground";
import TitleStylizedUnderline from "../components/TitleStylizedUnderline";
import TableSkeleton from "../components/skeletons/TableSkeleton";
import { BatchBundle } from "../models/bundle";
import { Cohort } from "../models/cohort";
import { PutStudyRequest, Study, StudyId } from "../models/study";
import NewStudyModal from "./modals/NewStudyModal";
import StudyTable from "./tables/StudyTable";

export const getStudyCurrentState = (study: Study) => {
  return study.progress_status.reduce((max, current) => {
    return new Date(max.start_date) > new Date(current.start_date) ? max : current;
  });
};

export default function StudiesPage() {
  const cohortQuartzClient = React.useContext(CohortQuartzClientContext);
  const studyQuartzClient = React.useContext(StudyQuartzClientContext);
  const adminQuartzClient = React.useContext(AdminQuartzClientContext);
  const [serverError, setServerError] = React.useState<string | null>("");
  const [loading, setLoading] = React.useState<boolean>(true);
  const [openNewStudyModal, setOpenNewStudyModal] = React.useState(false);
  const [studies, setStudies] = React.useState<Study[]>([]);
  const [cohorts, setCohorts] = React.useState<Cohort[]>([]);
  const navigate = useNavigate();
  const [refresh, setRefresh] = React.useState(new Date().getTime());

  const deleteStudies = async (ids: StudyId[]) => {
    const bundle = {
      entry: ids.map((id) => {
        return {
          request: {
            method: "DELETE",
            url: `Study/${id}`,
          },
        };
      }),
    } as BatchBundle;

    try {
      await adminQuartzClient.batchOperation(bundle);
    } catch (e: any) {
      setServerError(e.message);
    }

    setRefresh(new Date().getTime());
  };

  const handleCreateStudy = async (putStudyRequest: PutStudyRequest) => {
    try {
      setLoading(true);
      const study = await studyQuartzClient.postNewStudy(putStudyRequest);
      navigate(`/study/${study.study_id}`);
    } catch (e: any) {
      setServerError(e.message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const getStudies = async () => {
      try {
        setLoading(true);
        const result = await studyQuartzClient.listStudies();
        setStudies(result.entry as Study[]);
      } catch (e: any) {
        setServerError(e.message);
      } finally {
        setLoading(false);
      }
    };

    getStudies();
  }, [studyQuartzClient, refresh]);

  React.useEffect(() => {
    const getCohorts = async () => {
      try {
        const result = await cohortQuartzClient.listCohorts();
        setCohorts(result);
      } catch (e: any) {
        setServerError(e.message);
      }
    };

    getCohorts();
  }, [cohortQuartzClient]);

  return (
    <Box sx={{ p: 4 }}>
      <HexagonBackground color="red" />
      <NewStudyModal
        open={openNewStudyModal}
        setOpen={setOpenNewStudyModal}
        handleCreateStudy={handleCreateStudy}
        cohorts={cohorts}
      />
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Box>
              <Typography level="h2">
                <TitleStylizedUnderline color="red">Studies</TitleStylizedUnderline>
              </Typography>
              <Typography>Studies are research projects or clinical trials.</Typography>
            </Box>
            <Stack direction="row" spacing={2}>
              <Button
                startDecorator={<AddIcon />}
                onClick={() => {
                  setOpenNewStudyModal(true);
                }}
                color="danger"
              >
                Add a study
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid xs={12} sx={{ mt: 4, flexGrow: 1 }}>
          {loading ? (
            <Box sx={{ height: "70vh", overflow: "hidden" }}>
              <TableSkeleton />
            </Box>
          ) : (
            <>
              {studies.length > 0 ? (
                <StudyTable studies={studies} deleteStudies={deleteStudies} />
              ) : (
                <Sheet
                  variant="outlined"
                  sx={{
                    p: 4,
                    textAlign: "center",
                    borderRadius: "sm",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "70vh",
                    justifyContent: "center",
                  }}
                >
                  <Typography level="h1" color="neutral">
                    You do not have any study yet.
                  </Typography>
                  <Box>
                    <Button
                      sx={{ mt: 2 }}
                      startDecorator={<AddIcon />}
                      onClick={() => {
                        setOpenNewStudyModal(true);
                      }}
                      color="danger"
                    >
                      Add a study
                    </Button>
                  </Box>
                </Sheet>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
