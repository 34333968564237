/* AUTOGENERATED: DO NOT EDIT */
import { OmopDbReport } from "./analytics";
import { UnixTimestamp } from "./common";
import { CriteriaGroup } from "./criteria";
import { CodeableConcept, Period, Quantity, Range } from "./structuration";

/**
 * A group taht contains subjects that satisfy a given set
 * of criteria for a duration of time.
 */
export interface Cohort {
  cohort_id: CohortId;

  /** A name for the cohort */
  name: string;

  /** A human readble description of the cohort */
  description: string;

  /** A set of criteria that all patients should respect. */
  entry_event: Array<CriteriaGroup>;

  /** A set of criteria that all patients should respect. */
  inclusion_criteria: Array<CriteriaGroup>;

  /** The subjects that are excluded from the cohort. */
  excluded_subjects: Array<Subject>;
  subjects: Array<Subject>;

  /** The name of original database used to create the cohort. */
  db_name: string;
  statistics?: OmopDbReport | null;
  snapshot_id?: UnixTimestamp | null;

  /** Last update date of the cohort. */
  update_date?: UnixTimestamp | null;

  /** Date at which the cohort has been deleted. */
  delete_date?: UnixTimestamp | null;
}

/** This is a "NewType" (see https://kubyshkin.name/posts/newtype-in-typescript/). */
export type CohortId = string & { readonly __tag: unique symbol };

/** Creates a CohortId from a string. */
export const cohortId = (value: string) => value as CohortId;

/**
 */
export type CriteriaValue = string | Quantity | CodeableConcept | Range;

export enum ExportFormat {
  DATAFRAME = "DATAFRAME",
  CSV = "CSV",
  SQLITE = "SQLITE",
  ODH = "ODH",
  CLINICAL_TABLE = "CLINICAL_TABLE",
}

export interface InitCohort {
  name: string;
  description: string;
  db_name: string;
}

export interface PutCohort {
  name: string;
  description: string;
  db_name: string;
  entry_event: Array<CriteriaGroup>;
  inclusion_criteria: Array<CriteriaGroup>;
  excluded_subjects: Array<Subject>;
}

export interface PutCohortWithSubjects {
  name: string;
  description: string;
  db_name: string;
  entry_event: Array<CriteriaGroup>;
  inclusion_criteria: Array<CriteriaGroup>;
  excluded_subjects: Array<Subject>;
  subjects: Array<Subject>;
  stat_report?: OmopDbReport | null;
}

/** A criteria is a characteristic of inclusion or exclusion. */
export interface Statistic {
  category: string;
  label: string;
  result: Array<number | number | string | Range>;
}

/**
 */
export type StatisticValue = number | number | string | Range;

/** Details about the subject in the cohort. */
export interface Subject {
  person_id: number;
  person_source_value: string;

  /** The observation period for which the subject belonged to the group */
  period: Period;

  /** Date of entry in the group */
  entry_date: string;

  /** If subject is no longer in the group */
  inactive: boolean;
}
