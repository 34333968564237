import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Grid from "@mui/joy/Grid";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import React from "react";
import SnackbarNotification from "../common/SnackbarNotification";
import { SearchBar } from "../annotation/molecules/SearchBar";
import { QuestionAnsweringClientContext, TaskQuartzClientContext } from "../clients/contexts";
import { DisplayAnswerFacts } from "../components/QuestionAnswering/DisplayAnswerFacts";
import { PersonId } from "../models/person";
import { Question, QuestionAnswer } from "../models/questionnaire";
import { Coding } from "../models/structuration";

type QuestionAnsweringProps = {
  personIds: PersonId[];
};

export const QuestionAnswering = ({ personIds }: QuestionAnsweringProps) => {
  // Context
  const questionAnsweringClient = React.useContext(QuestionAnsweringClientContext);
  const taskQuartzClient = React.useContext(TaskQuartzClientContext);
  
  // State
  const [questionsAnswers, setQuestionsAnswers] = React.useState<QuestionAnswer[]>([]);
  const [serverError, setServerError] = React.useState<string | null>("");
  

  // Handlers
  const handleSearch = async (coding: Coding) => {
    const question = { main_concept: [coding] } as Question;
    try {
      let task = await questionAnsweringClient.askQuestions([question], personIds)
      let taskCompleted = false;
      while (!taskCompleted) {
        try {
          task = await taskQuartzClient.getTaskById(task.task_id);
          if (task.status === "completed") {
            const results = task.output
            setQuestionsAnswers((prev) => [...prev, ...results]);
            taskCompleted = true;
            } else if (task.status === "failed") {
              setServerError("task failed");
              taskCompleted = true;
            };
          } catch (e: any) {
          setServerError(e.message);
          taskCompleted = true;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Poll every second
      } 
    }catch (e: any) {
      console.error(e.message);
    }
  };

  const handleRemove = (index: number) => {
    setQuestionsAnswers((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Stack spacing={2} direction="column" sx={{ mb: 2 }}>
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <SearchBar
        onChange={(coding) => coding && handleSearch(coding)}
        message="Search patient records"
      />
      {questionsAnswers.map((questionAnswers, index) => (
        <Grid xs={12} sm={6} key={index}>
          <Card variant="outlined" key={index}>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography>
                  <Typography level="title-lg">{questionAnswers.text} </Typography>
                  <Typography level="body-xs">
                    {questionAnswers.main_concept.map((c) => c.code).join(",")}
                  </Typography>
                </Typography>
                <IconButton color="neutral" size="sm" onClick={() => handleRemove(index)}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Box>
                <DisplayAnswerFacts
                  answerType={questionAnswers.answer_type || "concept-date"}
                  answers={questionAnswers.answer}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Stack>
  );
};
