import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import CloseIcon from "@mui/icons-material/Close";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Launch from "@mui/icons-material/Launch";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import Autocomplete from "@mui/joy/Autocomplete";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import Grid from "@mui/joy/Grid";
import Link from "@mui/joy/Link";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import React from "react";
import { useLocation } from "react-router-dom";
import { SearchBar } from "../annotation/molecules/SearchBar";
import { CohortQuartzClientContext, QuestionAnsweringClientContext } from "../clients/contexts";
import { DisplayAnswerFacts } from "../components/QuestionAnswering/DisplayAnswerFacts";
import { Cohort, CohortId } from "../models/cohort";
import { PersonId } from "../models/person";
import { Question, QuestionAnswer, Questionnaire } from "../models/questionnaire";
import { Coding } from "../models/structuration";
import ListQuestionnaireModal from "./ListQuestionnaireModal";
import SaveQuestionnaireModal from "./SaveQuestionnaireModal";
import {TaskQuartzClientContext } from "../clients/contexts";
import SnackbarNotification from "../common/SnackbarNotification";

export const ExportBuilder = () => {
  const location = useLocation();
  const cohortId = location.pathname.split("/")[2] as CohortId;

  // Context
  const cohortClient = React.useContext(CohortQuartzClientContext);
  const questionAnsweringClient = React.useContext(QuestionAnsweringClientContext);
  const taskQuartzClient = React.useContext(TaskQuartzClientContext);

  // State
  const [loading, setLoading] = React.useState(true);
  const [exportLoading, setExportLoading] = React.useState(false);
  const [cohort, setCohort] = React.useState<Cohort | null>(null);
  const [personIds, setPersonIds] = React.useState<PersonId[]>([]);
  const [questionsAnswers, setQuestionsAnswers] = React.useState<QuestionAnswer[]>([]);
  const [preBuiltQuestions, setPreBuiltQuestions] = React.useState<Question[]>([]);
  const [modalSaveQuestionnaire, setModalSaveQuestionnaire] = React.useState(false);
  const [modalListQuestionnaires, setModalListQuestionnaires] = React.useState(false);
  const [serverError, setServerError] = React.useState<string | null>("");

  // Handlers
  async function onConceptSearch(coding: Coding) {
    const question = { main_concept: [coding] } as Question;
    await askQuestions([question]);
  }

  async function askQuestions(questions: Question[]) {
    try {
      setLoading(true);
      let task = await questionAnsweringClient.askQuestions(questions, personIds)
      let taskCompleted = false;
      while (!taskCompleted) {
        try {
          task = await taskQuartzClient.getTaskById(task.task_id);
          if (task.status === "completed") {
            const results = task.output
            setQuestionsAnswers((prev) => [...prev, ...results]);
            taskCompleted = true;
            } else if (task.status === "failed") {
              setServerError("task failed");
              taskCompleted = true;
            };
          } catch (e: any) {
          setServerError(e.message);
          taskCompleted = true;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Poll every second
      }
    } catch (e: any) {
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  }

  async function onExport() {
    try {
      setExportLoading(true);
      const blob = await questionAnsweringClient.exportQuestionAnswers(questionsAnswers);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `question_answers.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (e: any) {
      console.log(e);
    } finally {
      setExportLoading(false);
    }
  }

  async function saveQuestionnaire(title: string, description: string) {
    try {
      const questionnaire = {
        title,
        description,
        item: questionsAnswers.map((qa) => qa),
      };
      await questionAnsweringClient.saveQuestionnaire(questionnaire);
    } catch (e: any) {
      console.error(e.message);
    }
  }

  async function updateQuestionnaire(questionnaire: Questionnaire) {
    const updatedQuestionnaire = questionnaire;
    updatedQuestionnaire.item = questionsAnswers.map((qa) => qa);
    try {
      await questionAnsweringClient.updateQuestionnaire(
        questionnaire.questionnaire_id,
        questionnaire
      );
    } catch (e: any) {
      console.error(e.message);
    }
  }

  const handleRemove = (index: number) => {
    setQuestionsAnswers((prev) => prev.filter((_, i) => i !== index));
  };

  React.useEffect(() => {
    async function fetchCohort() {
      try {
        setLoading(true);
        const result = await cohortClient.getCohortById(cohortId);
        setPersonIds(result.subjects.map((subject) => subject.person_source_value as PersonId));
        setCohort(result);
      } catch (e: any) {
        console.error(e.message);
      } finally {
        setLoading(false);
      }
    }
    async function fetchPreBuiltQuestions() {
      try {
        const result = await questionAnsweringClient.getPrebuiltQuestions();
        setPreBuiltQuestions(result);
      } catch (e: any) {
        console.error(e.message);
      }
    }

    fetchCohort();
    fetchPreBuiltQuestions();
  }, [cohortId, cohortClient, questionAnsweringClient]);

  if (!cohort) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="small" />}
          sx={{ pl: 0 }}
        >
          <Link
            underline="hover"
            color="neutral"
            href={`/cohorts`}
            startDecorator={<GroupOutlinedIcon fontSize="small" />}
            sx={{ fontSize: 12, fontWeight: 500 }}
          >
            Cohorts
          </Link>
          <Link
            underline="hover"
            color="neutral"
            href={`/cohort/${cohortId}`}
            sx={{ fontSize: 12, fontWeight: 500 }}
          >
            {cohort.name} ({cohort.subjects.length} patient{cohort.subjects.length > 1 ? "s" : ""})
          </Link>
          <Typography color="primary" sx={{ fontWeight: 500, fontSize: 12 }}>
            Custom export
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {serverError && <SnackbarNotification text={serverError} color="danger" />}
        <Typography level="h2" component="h1">
          Custom export
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            color="primary"
            startDecorator={<DownloadRoundedIcon />}
            size="sm"
            loading={exportLoading}
            loadingPosition="start"
            onClick={onExport}
          >
            Download Excel
          </Button>
          {questionsAnswers.length > 0 ? (
            <Button
              color="neutral"
              size="sm"
              startDecorator={<SaveAsRoundedIcon />}
              onClick={() => setModalSaveQuestionnaire(true)}
            >
              Save as a template
            </Button>
          ) : (
            <Button
              color="neutral"
              size="sm"
              startDecorator={<SaveAsRoundedIcon />}
              onClick={() => setModalListQuestionnaires(true)}
            >
              Open existing template
            </Button>
          )}
        </Stack>
      </Box>
      {loading ? (
        <CircularProgress color="success" />
      ) : (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid xs={12} sm={6}>
              <SearchBar
                onChange={(coding) => coding && onConceptSearch(coding)}
                message="Search a concept of interest"
                isSmall
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <Autocomplete
                options={preBuiltQuestions}
                getOptionLabel={(option) => option.text || ""}
                placeholder="Search among pre-built questions"
                onChange={(event, newValue) => {
                  newValue && askQuestions([newValue]);
                }}
              />
            </Grid>
          </Grid>
          <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
            {questionsAnswers.length === 0 ? (
              <div>No concept selected</div>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Sheet
                  sx={{
                    height: "80vh",
                    overflow: "auto",
                    "--Table-firstColumnWidth": "70px",
                    borderRadius: "sm",
                  }}
                  variant="outlined"
                >
                  <Table
                    borderAxis="bothBetween"
                    hoverRow
                    variant="plain"
                    sx={{
                      tableLayout: "auto",
                      "& tr > *:first-child": {
                        position: "sticky",
                        left: 0,
                        bgcolor: "background.surface",
                      },
                    }}
                    stickyHeader
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "var(--Table-firstColumnWidth)", zIndex: 99 }}>
                          ID patient
                        </th>
                        {questionsAnswers.map((questionAnswer, index) => (
                          <th key={index}>
                            <Stack direction="row" spacing={2}>
                              <Typography
                                endDecorator={
                                  <CloseIcon
                                    onClick={() => handleRemove(index)}
                                    fontSize="small"
                                    color="action"
                                    sx={{ cursor: "pointer" }}
                                  />
                                }
                              >
                                {questionAnswer.text}
                              </Typography>
                            </Stack>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {personIds.map((personId) => (
                        <tr key={personId}>
                          <td style={{ zIndex: 50 }}>
                            <Link
                              underline="hover"
                              href={"/patient/" + personId}
                              target="_blank"
                              rel="noopener"
                              endDecorator={<Launch fontSize="small" />}
                            >
                              {personId.length > 10 ? `${personId.substring(0, 10)}...` : personId}
                            </Link>
                          </td>
                          {questionsAnswers.map((questionAnswer, index) => (
                            <td key={index}>
                              <DisplayAnswerFacts
                                answerType={questionAnswer.answer_type || "concept-date"}
                                answers={questionAnswer.answer.filter(
                                  (a) => a.person_id === personId
                                )}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th scope="row">Totals</th>
                        {questionsAnswers.map((questionAnswer, index) => (
                          <td key={index}>{questionAnswer.answer?.length}</td>
                        ))}
                      </tr>
                    </tfoot>
                  </Table>
                </Sheet>
              </Box>
            )}
          </Stack>
        </React.Fragment>
      )}
      <SaveQuestionnaireModal
        open={modalSaveQuestionnaire}
        setOpen={setModalSaveQuestionnaire}
        handleSaveNewQuestionnaire={saveQuestionnaire}
        handleUpdateQuestionnaire={updateQuestionnaire}
      />
      <ListQuestionnaireModal
        open={modalListQuestionnaires}
        setOpen={setModalListQuestionnaires}
        handleSelectQuestionnaire={askQuestions}
      />
    </Box>
  );
};
