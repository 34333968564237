import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CircularProgress from "@mui/joy/CircularProgress";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import React from "react";
import { Link } from "react-router-dom";
import { DocumentQuartzClientContext, TaskQuartzClientContext } from "../clients/contexts";
import SnackbarNotification from "../common/SnackbarNotification";
import FileUploader from "../document/molecules/FileUploader";
import { PutDocumentRequest } from "../models/document";
import { Note } from "../models/note";
import { PersonId } from "../models/person";
import { TaskId, TaskStatus } from "../models/task";

interface TableFile {
  fileName: string;
  notes: Note[];
  message: TaskStatus;
  taskId?: TaskId;
}

const getStatus = (file: TableFile): TaskStatus => {
  if (!!file.message) return file.message;
  return "requested";
};

const taskStatusToTitle = (status: TaskStatus) => {
  switch (status) {
    case "completed":
      return "Completed";
    case "failed":
      return "Failed";
    case "in-progress":
      return "In Progress";
    default:
      return "Requested";
  }
};

const UploadedFilesTable = ({ tableFiles }: { tableFiles: TableFile[] }) => {
  return (
    <Table
      sx={{
        td: { verticalAlign: "middle" },
        borderRadius: "sm",
        "& th:first-of-type": { borderTopLeftRadius: "6px !important" },
        "& th:last-of-type": { borderTopRightRadius: "6px !important" },
      }}
      variant="outlined"
    >
      <thead style={{ borderRadius: "8px" }}>
        <tr>
          <th style={{ width: "50px" }}></th>
          <th>Filename</th>
          <th>Status</th>
          <th>Related note(s)</th>
        </tr>
      </thead>
      <tbody>
        {tableFiles.map((file, key) => {
          return (
            <React.Fragment key={key}>
              <tr>
                <td style={{ borderBottom: 0, paddingTop: "12px" }}>
                  {(() => {
                    const status = getStatus(file);
                    switch (status) {
                      case "in-progress":
                        return <CircularProgress size="sm" />;
                      case "completed":
                        return <CheckIcon />;
                      case "failed":
                        return <ErrorOutlineIcon />;
                      default:
                        return <CircularProgress size="sm" />;
                    }
                  })()}
                </td>
                <td style={{ borderBottom: 0 }}>{file.fileName}</td>
                <td style={{ borderBottom: 0 }}>{taskStatusToTitle(getStatus(file))}</td>
                <td style={{ borderBottom: 0 }}>
                  {file.notes.map(
                    (note, i) =>
                      note.note_id && (
                        <Link to={`/note/${note.note_id}`} target="_blank" key={i}>
                          <p
                            onClick={() => {
                              const id = document.getElementById(i.toString());

                              if (id) {
                                id.className = "has-text-dark";
                              }
                            }}
                            id={i.toString()}
                            className=""
                          >
                            Open
                          </p>
                        </Link>
                      )
                  )}
                </td>
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

interface UploadDocumentProps {
  personId?: PersonId | null;
}

export function UploadDocument({ personId }: UploadDocumentProps) {
  // Contexts
  const documentQuartzClient = React.useContext(DocumentQuartzClientContext);
  const taskQuartzClient = React.useContext(TaskQuartzClientContext);

  // Constants
  const fileTypes = ["PDF", "TXT", "DOCX", "DOC"];
  const fileMaxSize = 6;

  // States
  const [loading, setLoading] = React.useState(false);
  const [serverError, setServerError] = React.useState<string | null>("");
  const [tableFiles, setTableFiles] = React.useState<TableFile[]>([]);

  const remove_prefix = (string: string) => {
    return string.split(";base64,")[1];
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(remove_prefix(fileReader.result as string));
      };
      fileReader.onerror = reject;
    });
  };

  const pollTaskStatus = async (taskId: TaskId, fileIndex: number) => {
    let taskCompleted = false;

    while (!taskCompleted) {
      try {
        const task = await taskQuartzClient.getTaskById(taskId);
        if (task.status === "completed" || task.status === "failed") {
          setTableFiles((prevTableFiles) => {
            const updatedFiles = [...prevTableFiles];
            if (fileIndex >= 0 && fileIndex < updatedFiles.length) {
              updatedFiles[fileIndex] = {
                ...updatedFiles[fileIndex],
                message: task.status,
                notes: task.output ? task.output : [],
              };
            }
            return updatedFiles;
          });
          taskCompleted = true;
        }
      } catch (e: any) {
        setServerError(e.message);
        taskCompleted = true;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Poll every second
    }
  };

  const handleChange = async (files: File | Array<File>) => {
    setLoading(true);
    const fileArray = Array.isArray(files)
      ? files
      : files instanceof FileList
        ? Array.from(files)
        : [files];

    await Promise.all(
      fileArray.map(async (file) => {
        const tableFile = {
          fileName: file.name,
          notes: [],
          message: "in-progress",
        } as TableFile;

        setTableFiles((prevTableFiles) => [...prevTableFiles, tableFile]);

        try {
          const putDocumentRequest = {
            content: await fileToBase64(file),
            filename: file.name,
            content_type: file.type,
            person_id: personId,
          } as PutDocumentRequest;

          const task = await documentQuartzClient.postDocument(putDocumentRequest);
          setTableFiles((prevTableFiles) => {
            const updatedFiles = [...prevTableFiles];
            const fileIndex = updatedFiles.findIndex((f) => f.fileName === file.name);

            if (fileIndex !== -1) {
              updatedFiles[fileIndex] = {
                ...updatedFiles[fileIndex],
                taskId: task.task_id,
              };
            }

            return updatedFiles;
          });
          pollTaskStatus(task.task_id, fileArray.indexOf(file));
        } catch (e: any) {
          setServerError(e.message);
          setTableFiles((prevTableFiles) => {
            const updatedFiles = [...prevTableFiles];
            const fileIndex = updatedFiles.findIndex((f) => f.fileName === file.name);

            if (fileIndex !== -1) {
              updatedFiles[fileIndex] = {
                ...updatedFiles[fileIndex],
                message: "failed",
              };
            }

            return updatedFiles;
          });
        }
      })
    );

    setLoading(false);
  };

  return (
    <Stack spacing={2} direction="column">
      {serverError && <SnackbarNotification text={serverError} color="danger" />}
      <FileUploader
        handleChange={handleChange}
        multiple={true}
        name="file"
        types={fileTypes}
        maxSize={fileMaxSize}
        disabled={loading}
      />
      {tableFiles && tableFiles.length > 0 && <UploadedFilesTable tableFiles={tableFiles} />}
    </Stack>
  );
}
